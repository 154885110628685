<template lang="pug">
    div.main-container(:style="{backgroundSize:'cover', backgroundImage:'url(' + require('@/assets/img2/insigne-bg-stroke-grey.png') + ')'} ")
        div.overlay
        
        <!--  BEGIN CONTENT PART  -->
        div.main-content(id="content")
            div.layout-px-spacing
                div.page-header
                    div.page-title
                        h3 {{ $t('adminassign.centeradmin')}}
                    div
                       nav.breadcrumb-one(aria-label="breadcrumb")
                          ol.breadcrumb
                              li.breadcrumb-item
                                router-link(to="/pages/home") {{ $t('headers.dashboard')}}
                              li.breadcrumb-item
                                router-link(to="/pages/dashboardCenter") {{ $t('headers.centers')}}
                              li.breadcrumb-item.active(aria-current="page") {{ $t('adminassign.centeradmin')}}
                              
                div.row.mt-5.pb-5
                    div.col-lg-4.col-md-4.layout-spacing
                        div.statbox.widget.box.box-shadow
                            div.widget-header
                                div.row
                                    div.col-xl-12.col-md-12.col-sm-12.col-12.text-center
                                        h4 {{ $t('adminassign.searchuser')}}

                                        div.widget-content.widget-content-area
                                            div.mx-auto.text-center

                                                div.form-group.mb-6
                                                    label {{ $t('adminassign.choose')}}          
                                                    select.selectpicker.col-12.mx-auto(v-model="idSelectedCenter")
                                                        option(value="0" selected) {{ $t('headers.select')}}
                                                        option(:value=" MyCenter.idCenter" v-for="MyCenter in MyCenters") {{ MyCenter.sCenterName }}

                                                div.form-group.mb-6
                                                    label(for="sEmail") {{ $t('adminassign.mail')}}
                                                    input.col-12.mx-auto(id="sEmail" type="text" :placeholder="$t('adminassign.mail')" v-model="model.sEmail")

                                                div.form-group.mb-6
                                                    label(for="sName") {{ $t('adminassign.name')}}
                                                    input.col-12.mx-auto(id="sName" type="text" :placeholder="$t('adminassign.name')" v-model="model.sName")

                                                div.form-group.mb-6
                                                    label(for="sLastName") {{ $t('adminassign.last')}}
                                                    input.col-12.mx-auto(id="sLastName" type="text" :placeholder="$t('adminassign.last')" v-model="model.sLastName")                                                                                                        

                                                div.form-group.mb-3
                                                    button.btn.btn-primary.btn-block(type="button" @click="findUsers")
                                                        template {{ $t('adminassign.search')}} 

                    div.col-lg-8.col-md-8.layout-spacing
                        div.statbox.widget.box.box-shadow
                            div.widget-header
                                div.row
                                    div.col-xl-12.col-md-12.col-sm-12.col-12.text-center
                                        h4 {{ $t('adminassign.resultsearch')}} 
                                div.widget-content.widget-content-area
                                    div.mx-auto.text-center

                                        div.table-responsive.mt-5
                                            table.table.table-bordered.table-hover.mb-4
                                                thead
                                                    tr
                                                        th {{ $t('adminassign.mail')}}
                                                        th {{ $t('adminassign.name')}} 
                                                        th {{ $t('adminassign.associdcenter')}} 
                                                        th(colspan=2) {{ $t('adminassign.actions')}}                                                 
                                                tbody
                                                    tr(v-for="user in users")
                                                        td {{ user.sEmail }}
                                                        td {{ user.sName }}
                                                        td {{ user.adminCenters }}
                                                        td 
                                                            button.btn.btn-primary.btn-block(type="button" @click="setAdminToCenter(user.idPerson, user.sName)") {{ $t('btns.btnadminconfirmButt')}}
                                                        td
                                                            button.btn.btn-primary.btn-block(type="button" @click="setTeacher(user.idPerson, user.sName)") {{ $t('btns.btnmakemaster')}}




</template>
<script>
/* eslint-disable */
export default {
    name: 'AdminAssign',
    data() {
        return {
            model: {
                sEmail: '',
                sName: '',
                sLastName: '',
            },
            modelRequest: {
                idPerson: 0,
                idCenter: 0,
                idPersonGrantPermision: 0
            },
            users: [],
            MyCenters: [],
            idSelectedCenter: 0
        }
    },
    methods: {
        async findUsers() {
            await axios.get(apiURL + 'User/getFindUsers?sEmail='+ this.model.sEmail + "&sName=" + this.model.sName + "&sLastName=" + this.model.sLastName, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.users = response.data.users
            } )
            .catch( (error)  => {
                console.log("error")
            } )
        },
        async setAdminToCenter(idPerson, sPersonName) {
            if(this.idSelectedCenter == 0)
            {
                Swal.fire(this.$t('msg.admmustchoose'), '', 'info')
                return
            }


            let obj = this.MyCenters.find(o => o.idCenter === this.idSelectedCenter);
            Swal.fire({
            title: this.$t('msg.admmustchoose') + obj.sCenterName + ' a ' + sPersonName ,
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: this.$t('btns.btnadminconfirmButt'),
            denyButtonText: this.$t('btns.btnadmindenyButt'),
            }).then((result) => {
            /* Read more about isConfirmed, isDenied below */
            if (result.isConfirmed) {
                console.log(this.idSelectedCenter + " --> " + idPerson)

                this.modelRequest.idPerson = idPerson
                this.modelRequest.idCenter = this.idSelectedCenter
                this.modelRequest.idPersonGrantPermision = +localStorage.CertIdP
                axios.post(apiURL + 'Center/setAndminToCenter', this.modelRequest, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
                } })
                .then( (response) => {
                    Swal.close();


                    if(response.data.bIsCorrect == false){
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: response.data.sErrors[0],
                            footer: ''
                        })
                        return
                    }
                    Swal.fire(this.$t('msg.adminSwal1'), '', 'success')
                } )
                .catch( (error) => {
                    Swal.close();
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: error,
                        footer: ''
                    })
                } )

                
            } else if (result.isDismissed) {
                Swal.fire(this.$t('msg.adminSwal2'), '', 'info')
            }
            })
        },
        async setTeacher(idPerson, sPersonName){
            if(this.idSelectedCenter == 0)
            {
                Swal.fire(this.$t('msg.admmustchoose'), '', 'info')
                return
            }
            Swal.fire({
            title: this.$t('msg.admintitle2') +sPersonName+ this.$t('msg.admintitle2a'),
            showDenyButton: false,
            showCancelButton: true,
            confirmButtonText: this.$t('btns.btnadminconfirmButt2'),
            denyButtonText: this.$t('btns.btnadmindenyButt2'),
            }).then((result) => {
                console.log(result)
                if (result.isConfirmed) {
                    console.log(this.idSelectedCenter + " --> " + idPerson)

                    this.modelRequest.idPerson = idPerson
                    this.modelRequest.idCenter = this.idSelectedCenter
                    this.modelRequest.idPersonGrantPermision = +localStorage.CertIdP
                    axios.post(apiURL + 'Center/AssigntTeacherPrivileges', this.modelRequest, { headers: {
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + localStorage.userToken
                    } })
                    .then( (response) => {
                        Swal.close();
                        if(response.data.bIsCorrect == false){
                            Swal.fire({
                                icon: 'error',
                                title: 'Error',
                                text: response.data.sErrors[0],
                                footer: ''
                            })
                            return
                        }
                        Swal.fire(this.$t('msg.adminSwal1'), '', 'success')
                    } )
                    .catch( (error) => {
                        Swal.close();
                        Swal.fire({
                            icon: 'error',
                            title: 'Error',
                            text: error,
                            footer: ''
                        })
                    } )

                }
                
                else if (result.isDismissed) {
                    Swal.fire(this.$t('msg.adminSwal2'), '', 'info')
                }

            })
        },

        async getCenterList(){
            await axios.get(apiURL + 'Center/getHierarchyCentersList?idPerson=' + localStorage.CertIdP, { headers: {
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + localStorage.userToken
            } })
            .then( (response) => {
                this.MyCenters = response.data.centers
            } )
            .catch( (error)  => {
                console.log("error")
            } )            
            
        }
    },
    mounted() {
            this.checkPermisions(this.$router.currentRoute.path)
            this.getCenterList()
            //this.idSelectedCenter = this.MyCenters[0].idCenter        
        }    
        
}
</script>